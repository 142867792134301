export const organisationsData = [
{
    id: 1,
    companyName: "ПУШКИНСКИЙ ФИЛИАЛ ООО «ГАЗПРОМ ТЕПЛОЭНЕРГО»",
    address: "141205, Московская обл., г. Пушкино, проезд Инессы Арманд, д. 4",
    contacts: "РУКОВОДИТЕЛЬ ФИЛИАЛА ЖИХАРЕВ ПАВЕЛ АЛЕКСАНДРОВИЧ + 7 (499) 993-38-70",
    serviceType: "ТЕПЛОСНАБЖЕНИЕ ДОГОВОР № 568 ОТ 17.03.2017 Г. ДОГОВОР № 1168 ОТ 14.05.2021 Г."
},

{
    id: 2,
    companyName: "МУП ЩМР «МЕЖРАЙОННЫЙ ЩЕЛКОВСКИЙ «ВОДОКАНАЛ»",
    address: "ЮР.АДРЕС: МО., ЩЕЛКОВСКИЙ Р-Н., Г. ЩЕЛКОВО, УЛ. СВИРСКАЯ, Д. 1 АДРЕС ОБОСОБЛЕННОГО ПОДРАЗДЕЛЕНИЯ: 141205, МОСКОВСКАЯ ОБЛ., Г. ПУШКИНО, УЛ. УЧИНСКАЯ, Д. 16",
    contacts: "ДИРЕКТОР ФИЛИАЛА МУП ЩМР «МЩВ» - «ВОДОКАНАЛ ПУШКИНСКОГО РАЙОНА» ГРИЩЕНКО КОНСТАНТИН ВИКТОРОВИЧ +7 (496)532-08-57",
    serviceType: "ВОДОСНАБЖЕНИЕ И ВОДООТВЕДЕНИЕ ДОГОВОР № 2130-Ф ОТ 17.07.2017 Г. ДОГОВОР № 2548-ОДН-Ф ОТ 03.12.2019 Г."
},

{
    id: 3,
    companyName: "ПАО «Мосэнергосбыт»",
    address: "117312, г. Москва,ул. Вавилова, д. 9",
    contacts: "Начальник Северо-Восточного ТО Ненс Виталий Валерьевич 8(496)535-05-05",
    serviceType: "Электроснабжение Договор № 65249425 от 16.10.2017 г. Договор № 65293051  от 29.12.2017 г. Договор  №  65293551 от 29.12.2017 г. Договор № 65292551 от 29.12.2017 г. Договор № 65292851 от 29.12.2017 г. Договор №65293451 от 29.12.2017 г. Договор № 65293251 от 29.12.2017 г. Договор № 65293151 от 29.12.2017 г.  Договор № 65292951 от 29.12.2017 г. Договор № 65292751 от 29.12.2017 г. Договор № 65292651 от 29.12.2017 г."
},

{
    id: 4,
    companyName: "ООО «Софрино»",
    address: "141273,  Московская обл., Пушкинский р-н., Территория Парк отель Софрино, стр. 1",
    contacts: "Генеральный директор БАЛУСОВ СТАНИСЛАВ АЛЕКСЕЕВИЧ",
    serviceType: "Договор № б/н оказание услуг по водоснабжению, водоотведению, очистке и утилизации сточных вод от 01.04.2017 г. Договор № б/н поставки тепловой энергии и теплоносителя от 01.04.2017 г."
},

{
    id: 5,
    companyName: "ГУП МО «Мособлгаз»",
    address: "Юр.адрес: 143026, Московская обл., Одинцовский р-н., р.п. Новоивановское, ул. Калинина, д. 1 Адрес обособленного подразделения: 141002, МО, г. Мытищи, ул. Белобородова, д. 6",
    contacts: "Директор филиала ГУП МО «Мособлгаз» «Мытищимежрайгаз»  Пантюхин Сергей Борисович 8-495-583-22-11",
    serviceType: "Договор  № 1709 Н Газоснабжение от 17.05.2017 г."
},

{
    id: 6,
    companyName: "ООО «Московский областной единый информационно-расчетный центр»",
    address: "142280, Московская обл., г. Протвино, ул. Победы, д. 2, Московская обл., г. Пушкино, ул. Тургенева, д. 13",
    contacts: "Начальник управления Пушкино МИХАЙЛОВА ЮЛИЯ ЮРЬЕВНА",
    serviceType: "Договор  № 812533017 об организации расчетов за жилищно-коммунальные услуги от 01.04.2017 г."
},

{
    id: 7,
    companyName: "МФЦ «Многофункциональный центр предоставления государственных и муниципальных услуг»",
    address: "141270 Московская область, г. Пушкино, Московский пр-т , д. 54А",
    contacts: "Левченко Ирина Сергеевна",
    serviceType: "Договор б/н Ведение информационной базы по учету лиц, зарегистрированных в жилищном фонде на территории муниципального района от 21.06.2017 г."
},

{
    id: 8,
    companyName: "ООО «СЕРГИЕВО-ПОСАДСКИЙ РЕГИОНАЛЬНЫЙ ОПЕРАТОР»",
    address: "ЮР.АДРЕС: 115184, Г. МОСКВА, УЛ. БОЛЬШАЯ ТАТАРСКАЯ, Д. 9 ПОЧТОВЫЙ АДРЕС: 141207, МО., Г. ПУШКИНО, УЛ. ГРИБОЕДОВА, Д. 7, ОФИС 509",
    contacts: "ДИРЕКТОР ЗАМОТАЕВ СЕРГЕЙ АЛЕКСАНДРОВИЧ",
    serviceType: "ВЫВОЗ МУСОРА ДОГОВОР № СПРО-2018-0000003 ОТ 10.10.2018 Г."
},

{
    id: 9,
    companyName: "ООО «Мособлгаз»",
    address: "141002 Московская область, г. Мытищи, ул. Белобородова д. 6",
    contacts: "директор филиала АО «Мособлгаз» «Север» - Сирица И.В.",
    serviceType: "договор № 1001709/ОД от 09.01.2024 г. (техническое обслуживание и ремонт внутридомового газового оборудования в МКД.)"
},
{
    id: 10,
    companyName: "ООО «Добродел»",
    address: "141190, Московская обл., г. Фрязино, ул. Вокзальная, д. 15 пом. 1-13А",
    contacts: "Исполнительный директор Кутупов Наиль Фидаевич +7 925-846-90-05",
    serviceType: "проверка вентиляционных каналов и дымоходов"
}
]