export const energyData = [
    {
        id: 1,
        group: "Население и приравненные к ним, за исключением населения и потребителей, указанных в пунктах 2 и 3",
        measure: "руб./кВт.ч",
        price1: "6,73",
        price2: "7,33"
    },
    
    {
        id: 1.2,
        group: "Население и приравненные к ним. за исключением населения и потребителей, указанных в п.2 и 3 по зонам суток(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "8,21/3,24",
        price2: "8,94/3,70"
    },

    {
        id: 2.1,
        group: "Население, проживающее в городских населенных пунктах в домах, оборудованных электрическими плитами",
        measure: "руб./кВт.ч",
        price1: "5,05",
        price2: "5,57"
    },
    
     {
        id: 2.2,
        group: "Население, проживающее в городских населенных пунктах в домах, оборудованных электрическими плитами по зонам суток.(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "6,16/2,43",
        price2: "6,79/2,81"
    },

    {
        id: 3.1,
        group: "Население, проживающее в сельских населенных пунктах",
        measure: "руб./кВт.ч",
        price1: "4,71",
        price2: "5,13"
    },

    {
        id: 3.2,
        group: "Население, проживающее в сельских населенных пунктах по зонам суток(дневная/ночная).",
        measure: "руб./кВт.ч",
        price1: "5,75/2,27",
        price2: "6,26/2,59"
    }
    
    ]