export const housesData = [
    { id: 1, address: "п.Софрино, ул.1-ая Южная, д.18", tarif1: "28.83", tarif2: "32.87" },
    { id: 2, address: "п.Софрино, ул.1-ая Южная, д.20", tarif1: "28.83", tarif2: "32.87" },
    { id: 3, address: "п.Софрино, ул.1-ая Южная, д.22", tarif1: "28.83", tarif2: "32.87" },
    { id: 4, address: "п.Софрино, ул.1-ая Южная, д.24", tarif1: "28.83", tarif2: "32.87" },
    { id: 5, address: "п.Софрино, ул.1-ая Южная, д.26", tarif1: "28.83", tarif2: "32.87" },
    { id: 6, address: "п.Софрино, ул.1-ая Южная, д.28", tarif1: "28.83", tarif2: "32.87" },
    { id: 7, address: "п.Софрино, ул.Комсомольская, д.13", tarif1: "28.83", tarif2: "32.87" },
    { id: 8, address: "п.Софрино, ул.Комсомольская, д.15", tarif1: "28.83", tarif2: "32.87" },
    { id: 9, address: "п.Софрино, ул.Комсомольская, д.21", tarif1: "28.83", tarif2: "32.87" },
    { id: 10, address: "п.Софрино, ул.Комсомольская, д.23", tarif1: "28.83", tarif2: "32.87" },
    { id: 11, address: "п.Софрино, ул.Комсомольская, д.25", tarif1: "28.83", tarif2: "32.87" },
    { id: 12, address: "п.Софрино, ул.Дальняя, д.6", tarif1: "28.83", tarif2: "32.87" },
    { id: 13, address: "п.Софрино, ул.Сетевая, д.2", tarif1: "28.83", tarif2: "32.87" },
    { id: 14, address: "п.Софрино, ул.Сетевая, д.4", tarif1: "28.83", tarif2: "32.87" },
    { id: 15, address: "п.Софрино, ул.Средняя, д.20", tarif1: "28.83", tarif2: "32.87" },
    { id: 16, address: "п.Софрино, ул.Средняя, д.22", tarif1: "28.83", tarif2: "32.87" },
    { id: 17, address: "п.Софрино, ул.Крайняя, д.10", tarif1: "28.83", tarif2: "32.87" },
    { id: 18, address: "п.Софрино, ул.Крайняя, д.12", tarif1: "28.83", tarif2: "32.87" },
    { id: 19, address: "п.Софрино, ул.Экспериментальная, д.5", tarif1: "28.83", tarif2: "32.87" },
    { id: 20, address: "п.Софрино, ул.Экспериментальная, д.7", tarif1: "28.83", tarif2: "32.87" },
    { id: 21, address: "п.Софрино, ул.Клубная, д.5", tarif1: "28.83", tarif2: "32.87" },
    { id: 22, address: "п.Софрино, ул.Клубная, д.8", tarif1: "27.96", tarif2: "32.00" },
    { id: 23, address: "п.Софрино, ул.Клубная, д.9", tarif1: "28.83", tarif2: "32.87" },
    { id: 24, address: "п.Софрино, ул.Клубная, д.12", tarif1: "28.83", tarif2: "32.87" },
    { id: 25, address: "п.Софрино, ул.Заводская, д.5", tarif1: "27.96", tarif2: "32.00" },
    { id: 26, address: "п.Софрино, ул.Ленина, д.7", tarif1: "28.83", tarif2: "32.87" },
    { id: 27, address: "д.Могильцы, д.1", tarif1: "28.83", tarif2: "32.87" },
    { id: 28, address: "п.Софрино, ул.Школьная, д.5", tarif1: "23.92", tarif2: "27.96" },
    { id: 29, address: "п.Софрино, ул.Дальняя, д.1", tarif1: "24.79", tarif2: "28.83" },
    { id: 30, address: "п.Софрино, ул.Дальняя, д.2", tarif1: "24.79", tarif2: "28.83" },
    { id: 31, address: "п.Софрино, ул.Дальняя, д.3", tarif1: "24.79", tarif2: "28.83" },
    { id: 32, address: "п.Софрино, ул.Дальняя, д.4", tarif1: "24.79", tarif2: "28.83" },
    { id: 33, address: "п.Софрино, ул.Дальняя, д.5", tarif1: "24.79", tarif2: "28.83" },
    { id: 34, address: "п.Софрино, ул.Ленина, д.1", tarif1: "24.79", tarif2: "28.83" },
    { id: 35, address: "п.Софрино, ул.Ленина, д.3", tarif1: "24.79", tarif2: "28.83" },
    { id: 36, address: "п.Софрино, ул.Ленина, д.5", tarif1: "24.79", tarif2: "28.83" },
    { id: 37, address: "п.Софрино, ул.Ленина, д.11", tarif1: "24.25", tarif2: "28.47" },
    { id: 38, address: "п.Софрино, ул.Ленина, д.13", tarif1: "24.79", tarif2: "28.83" },
    { id: 39, address: "п.Софрино, ул.Клубная, д.6", tarif1: "24.79", tarif2: "28.83" },
    { id: 40, address: "п.Софрино, ул.Клубная, д.10", tarif1: "24.79", tarif2: "28.83" },
    { id: 41, address: "п.Софрино, ул.Заводская, д.2а", tarif1: "23.92", tarif2: "27.96" },
    { id: 42, address: "п.Софрино, ул.Клубная, д.14", tarif1: "13.71", tarif2: "17.17" },
    { id: 43, address: "п.Софрино, ул.Клубная, д.3", tarif1: "13.71", tarif2: "17.17" },
    { id: 44, address: "п.Софрино, ул.Менделеева, д.40", tarif1: "11.59", tarif2: "14.96" },
    { id: 45, address: "п.Софрино, ул.Орджоникидзе, д.41", tarif1: "11.59", tarif2: "14.96" },
    { id: 46, address: "п.Софрино, ул.Сетевая, д.7", tarif1: "10.72", tarif2: "14.09" },
    { id: 47, address: "п.Софрино, ул.Сетевая, д.9", tarif1: "10.72", tarif2: "14.09" },
    { id: 48, address: "п.Софрино, ул.Сетевая, д.11", tarif1: "10.72", tarif2: "14.09" },
    { id: 49, address: "д.Митрополье, ул.Шоссейная, д.4", tarif1: "12.76", tarif2: "16.47" },
    { id: 50, address: "п.Софрино, ул.Дурова, д.17", tarif1: "6.83", tarif2: "12.11" },
    { id: 51, address: "п.Софрино, ул.Менделеева, д.31", tarif1: "2.98", tarif2: "5.98" },
    { id: 52, address: "п.Софрино, ул.Комсомольская, д.17", tarif1: "10.11", tarif2: "12.65" },
    { id: 53, address: "п.Софрино, ул.Дурова, д.13", tarif1: "3.42", tarif2: "6.85" },
    { id: 54, address: "п.Софрино, ул.Клубная, д.1", tarif1: "5.80", tarif2: "7.92" },
    { id: 55, address: "д.Талицы, д.1", tarif1: "3.42", tarif2: "6.85" }
  ];
  